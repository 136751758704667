import React, { useState, useEffect } from "react";
import MotionNumber from "motion-number";
import { motion } from "framer-motion";
import "./App.css"; // Ensure you import the CSS file

function App() {
  const calculateTimeLeft = () => {
    const difference = +new Date("2024-10-01T00:00:00") - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer); // Clean up the interval on component unmount
  }, []);

  return (
    <div className="App">
      <div className="countdown">
        <MotionNumber
          value={timeLeft.days}
          format={{ minimumIntegerDigits: 2 }}
        />
        :
        <MotionNumber
          value={timeLeft.hours}
          format={{ minimumIntegerDigits: 2 }}
        />
        :
        <MotionNumber
          value={timeLeft.minutes}
          format={{ minimumIntegerDigits: 2 }}
        />
        :
        <MotionNumber
          value={timeLeft.seconds}
          format={{ minimumIntegerDigits: 2 }}
        />
      </div>
      <div className="copyright">© 2024 Durkio & Jon Interprises</div>
    </div>
  );
}

export default App;
